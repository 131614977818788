<template>
    <swiper-slide class="h-full w-full overflow-hidden no-scrollbar" style="width: 94%">
        <div class="h-full relative w-full rounded-lg module p-2 flex flex-col z-40"
            :class="{'ml-0':filteredOportunities, '-ml-84': !filteredOportunities}" style="transition: all .3s">

            <div class="h-10 flex flex-row justify-start items-center px-1">
                <span class="text-font-gray font-semibold mr-auto">SMU</span>
                <!-- <i class="mdi mdi-filter-variant text-font-gray mr-1"></i>
                <div class="h-6 rounded-lg bg-box-80 flex flex-row justify-between items-center">
                    <multiselect label="name" v-model="sortBy_smu" :options="sortOptions" />
                </div> -->
            </div>

            <div class="h-auto w-full mb-1">

                <div class="h-full w-full flex flex-wrap overflow-x-auto no-scrollbar" v-if="legendColors">

                    <div class="flex-none " v-for="(pill, k) in legendColors" :key="k">

                        <div @click="toggleSlSelectedItems(k)" class="p-1 m-2px text-3xs flex flex-col justify-center items-center px-1 rounded-md mr-1" :style="getOpacity(pill, k)">

                            <span class="text-center">{{ pill.name }}</span>

                        </div>

                    </div>

                </div>

            </div>

            <loader :loading="loading_smu" />

            <div v-if="smuFiltered && smuFiltered.length > 0" class="flex-1 overflow-auto no-scrollbar" style="transition: all .3s">
                <ProjectsSMUMobile v-for="(el,index) in smuFiltered" :key="index" :row="el"></ProjectsSMUMobile>
            </div>
            <div v-else-if="!loading && smu.length === 0" class="h-full w-full flex flex-col justify-center items-center">
                <span class="module-title text-font-gray">No data in this section</span>
            </div>

            <div v-else class="h-full flex flex-col justify-center items-center">
                <span class="text-font-gray text-xs">No SL Selected</span>
            </div>

        </div>
    </swiper-slide>
</template>

<script>

import { state, actions } from '@/store';
const ProjectsSMUMobile = () => import('@/components/Mobile/ProjectsManagement/ProjectsSMUColumn/ProjectsSmu')

export default {
    props:['sl', 'sortBy'],
    name: "ProjectsSMUColumn",
    components: {
        ProjectsSMUMobile
    },
    data() {
        return {
            loading: false,
            filteredOportunities: true,
            sortOptions: this.$optionsFilterProjects,
            sortOrder: {name: 'ASC', value: 'asc'},
            smu: false,
            loading_smu: false
        }
    },
    methods: {
        preload() {
            this.smu = []
            this.loading_smu = true
        },
        getSmu() {
            this.axios.get('projects_management/ner_competency', {params: this.params}).then(response => {
                this.smu = response.data.data
                this.loading_smu = false
            })
        },
        closeSL(){
            this.$emit('closeSL')
        },
        getOpacity(pill, k) {
            if (this.slSelectedItems.includes(k)) {
                return 'color:' + pill.color + ';'
            } else {
                return 'display:none'
            }
        },
        toggleSlSelectedItems(item){ 
            actions.toggleSlSelectedItems(item) 
        },
    },
    computed: {
        params(){ 
            return state.globalParams 
        },
        legendColors() {
            return state.slLegendColors
        },
        smuFiltered() {
            let arr = []
            if (this.smu) {
                arr = this.smu.filter( item => {
                    return this.slSelectedItems.includes(item.id_sl)
                })

                if(this.sortBy.value === 'name'){
                    return this._.orderBy(arr , [this.sortBy.value],['asc'])
                } else {
                    return this._.orderBy(arr , [this.sortBy.value],['desc'])
                }

            }

        },
        slSelectedItems(){ 
            return state.slSelectedItems 
        },
    },
    watch: {
        params(n, o){ 
            this.getSmu() 
        }
    },
    mounted() {
        this.preload();
        if(this.params) {
            this.getSmu();
        }
    },
    
}
</script>

